@import "../../global.scss";
@import "../../breakpoints.scss";

.personal{
    background-color: #607a6b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media("<996px"){
        display: none;

    }
    
    

    h1{
        font-size: 4vw;
        color: whitesmoke;
        margin: 40px;
        
        
        @include media ( "<1410px"){
            height: 6vw;
            margin: 2vw;

        }
        
       
        }

    ul{
        margin: 20px;
        padding:0;
        list-style: none;
        display: flex;
        

        @include media ( "<505px"){
            height: 10vw;
            flex-direction: column;
            display: flex;
           

        }
        

        
    }

    .container{
        width: 60%;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
       


        .item{
            width: 14vw;
            height: 14vw;
            border-radius: 20px;
            border: 3px solid whitesmoke;
            margin: 15px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: whitesmoke;
            position: relative;
            transition: all .5s ease;
            cursor: pointer;

            h3{
                position: absolute;
                font-size: 2vw;
                text-align: center;

                
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
                
            }

            &:hover{
                background-color: $mainColor;
                img{
                    opacity: 0.2;
                    z-index:0;
                }
            }
        }
    }
}