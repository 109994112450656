@import "../../breakpoints.scss";
@import "../../global.scss";


.code{
    background-color:black;
    display: flex;
    box-sizing: border-box;



    align-items: center;
    justify-content: center;
    flex-direction: column;
    
   /*  grid-column: 3 /auto;
        grid-column-start: 3;
        grid-column-end: auto; */
   

  
   .skills{
       display: grid ;
        box-sizing: border-box;
    
        
        
       /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
       
       
       gap: 1rem;
       row-gap: 1rem;
       column-gap: 1rem;
       list-style: none;
       list-style-position: initial;
       list-style-image: initial;
       list-style-type: none;
       padding-inline-start: 0px;
       flex-direction: column;
       flex-wrap: wrap;
       
       
       
       
      
       @include media (">567px"){
        justify-items:center;
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    @include media ("<568px"){
        justify-items:center;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
       
     
       

       .skill{
           display: flex;
           align-items: center;
           -webkit-box-pack: center;
           -webkit-box-align:center;
           list-style: none;
           padding: 1rem;
           background: gray;
           box-shadow: lightgray;
           width: 7vw;
           height: 7vw;
           border-radius: 10px;
           border: 3px solid #bbc0b9;
           margin: 10px 20px;
           justify-content: center;
           color: white;
           /* position: relative; */
           overflow-y: hidden;
           cursor: pointer;
           

           h3{
               position: absolute;
               font-size: 1.5vw;
               opacity: 0;
           }

           img{
               width: 100%;
               height: 100%;
               object-fit: cover;
               z-index: 1;
           }

           &:hover{
               background-color: 
               black;
               img{
                   opacity: 0.2;
                   z-index:0;
               }
               h3{
                opacity: 1;
                z-index:0;  
            }
           }
           
       }
   }
    

    
    h1 {
    font-size: 4vw;
    color: $mainColor;
    height:15vh;
    overflow-y: hidden;
    align-items: center;
       
       @include media ("<565px"){
        font-size: 5vw;
        margin: 60px;
        height: 10vw;
        
      }
       
       
        }

    } 
