@import "../../global.scss";
@import "../../breakpoints.scss";

.portfolio{
    background-color: #375545;
    display: flex;
    flex-direction: column;
    align-items: center;
   

    h1{
        font-size: 4vw;
        color: $mainColor;
        margin:50px;
    
       
    }
    ul{
        margin: 20px;
        padding:0;
        list-style: none;
        display: flex;

        
    }

    .container{
        width: 70%;
        height:70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        overflow-x:auto;
       
        


        .item{
            width: 30vw;
            height: 20vw;
            border-radius: 10px;
            border: 3px solid whitesmoke;
            margin: 10px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            position: relative;
            transition: all .5s ease;
            cursor: pointer;

            h3{
                position: absolute;
                font-size: 20px;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }

            &:hover{
                background-color: $mainColor;
                img{
                    opacity: 0.2;
                    z-index:0;
                }
                
            }
        }
    }
 }