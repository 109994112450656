@import "../../breakpoints.scss";
@import "../../global.scss";


.work{
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
   

  
   
    

    
    h1 {
        font-size: 4vw;
        color: $mainColor;
        height: 70vh;
        text-align: center;
       
        @include media ("<996px"){
            font-size: 2.5em;
            
        }
        @media (max-height: 800px){
            height:80vh;
        }

        
       
       
        }

    .arrow {
        height: 50px;
        position: absolute;
        cursor: pointer;

        
    
        &.left {
            left: 2%;
            transform: rotateY(180deg);
          }
      
          &.right {
            right: 2%;
          }
    }


    .slider{
        height: 350px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;


        .container {
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;

           
            

            .item{
                width: 75%;
                height: 100%;
                background-color: white;
                border-radius: 50px;
                display:flex;
                align-items: center;
                justify-content: center;

                
                @include media ("<1190px"){
                    flex-direction: column;
                    width: 50vw;
                    height: 70%;
                    justify-content: center;
                    align-items: center;
                    
                }


                .left{
                    flex:7;
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include media("<1190px"){
                        flex: 4;
                    }

                    .leftContainer{
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        }
                        .pointthree{
                            font-style: bold;
                        }

                        h2{
                            font-size: 1.7vw;

                            @include media ( "<1190px"){
                                font-size: 1em;
                                text-align: center;
                                margin-top: 20px;
                               
                             }

                             

                            

                            
                        }
                        p{
                            font-size: 20px;
                            
                            

                            @include media ("<1190px"){
                                display: none;
                            }
                        }
                    }
                }

                .right{
                    flex:5;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                  

                    img{
                        width:50%;
                        transform: rotate(0deg);
                        overflow: hidden;
                        overflow-y: hidden;
                        
                    }
            
                }
            }
        }
    } 