@import "../../global.scss";
@import "../../breakpoints.scss";

.testimonials {
  background-color: #bbc0b9;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;

  @include mobile{
    justify-content: space-around;
  }
  


  h1 {
    font-size: 4vw;
    margin:20px;
    overflow-y: hidden;
    color: #1d322d;

    @include media ("<500px"){
      font-size: 2rem;
      margin: 7vh;
      height:10vh;
    }
  }

  .container {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
   

    @include mobile{
      flex-direction: column;
      height: 100%;
    }

    .card {
      width: 250px;
      height: 60vh;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -8px black;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      transition: all 1s ease;
      overflow-y: hidden;

      @include media("<507px"){
        padding:5px;
      }
    

      @include mobile{
        height:50vh;
        margin: 10px 0;
      }

      &.featured{
          width: 300px;
          height: 85%;
          margin: 0 30px;
          overflow-y: hidden;

          @include mobile{
            width: 250px;
            height: 50vh;
            margin: 1px;
          }
      }

      &:hover{
          transform: scale(1.1);
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: hidden;
        
        

        img {
          &.left,
          &.right {
            height: 25px;
            overflow-y: hidden;
          }
          &.user {
            border-radius: 50%;
            object-fit: cover;
            overflow-y: hidden;
            height: 5rem;
            width: 5rem;
            
           

            @include media("<769px"){
              width: 30px;
              height: 30px;
              margin: 10px;
            }
          }
        }
      }

      .center {
        padding: 10px;
        border-radius: 10px;
        background-color: rgb(250, 244, 245);
        
        @include media("<426px"){
          display:none;
        }
        @media (max-height: 850px){
          height: 30vh;
        }
    
        @include mobile{
          font-size: 12px;
          padding: 5px;
          height: 20vh;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        overflow: auto;
        overflow-y: hidden;

        h3 {
          margin-bottom: 5px;
          overflow-y: hidden;
          
          @include media("<769px"){
            padding: 20px;
            font-size: .9rem;
    
        }

         
        }
        h4{
            color: rgb(121, 115, 115);
            text-align: center;
            overflow-y: hidden;
            

            @include media("<940px"){
              display: none;
      
          }
        }
      }
    }
  }
}