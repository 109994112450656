@import "../../global.scss";

.personalList {
  font-size: 20px;
  margin-right: 50px;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;
  color:whitesmoke;

  @include mobile{
    margin-right: 20px;
    
  }

  &.active {
    background-color: $mainColor;
    color: white;
  }
}