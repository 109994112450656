@import "../../global.scss";
@import "../../breakpoints.scss";

.menu{
    width: 325px;
    height: 100vh;
    background-color: #1d322d;
    position: fixed;
    top: 0;
    right: -325px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;

    &.active {
        right:0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 2rem;
        font-weight: 300;
        color: $mainColor;
        text-shadow: 1px 1px #bbc0b9;
        width: 70%;

        @media screen and ( max-height: 768px){
            font-size: 1.7rem;
            margin-top: 22vh;
          }

        li{
            margin-bottom: 30px;
            a{
                font-size:inherit;
                color:inherit;
                text-decoration: none;
            }

            &:hover{
                font-weight: 500;
            }
        }
    }
}