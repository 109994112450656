@import "../../global.scss";
@import "../../breakpoints.scss";


.footer{
    background-color: #1d322d;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    

  .container{
    gap: 1rem;
          row-gap: 1rem;
          column-gap: 2rem;

      .header{
        align-items: center;
        justify-content: center;
        display:flex;

        h1{
          color: $mainColor;
          font-size: 2rem;
          text-align: center;
        }
        
        
      }
        
      .social{
          display: flex;
          list-style: none;
          align-items: center;
          justify-content: center;
          margin: .5vw;
          size: 3vw;
         
          gap: 3rem;
          row-gap: 1rem;
          column-gap: 1rem;

          @include media ( "<400px"){
            display: none;
          }



              .github{
                
              

                


                .linkedin{
                    font-size: 18px;
                    margin-right: 5px;
                }

                .mail{
                    font-size: 15px;
                    font-weight: 500;

                    
                }
            }
          }
        .rights{
          
            p{
            align-items: center;
            justify-content: center;
            display:flex;
            margin-top: 1vw;
            color: $mainColor;
            bottom: 0;
            text-align: center;
          }
        }
        .CV{
          
          a{
          align-items: center;
          justify-content: center;
          display:flex;
          margin-top: 1vw;
          color: $mainColor;
          bottom: 0;
          text-align: center;
        }
      }
  }
  }